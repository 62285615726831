import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Dolce Crespo <a href="https://instagram.com/dolcecrespo_"><i class="fa fa-instagram"></i></a></h1>
      </header>
    </div>
  );
}

export default App;
